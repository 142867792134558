import React, { Component } from 'react';
import fx from 'glfx';

import './App.css';

import IMG from './pug.png'

class App extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  state = {
    range: 0,
    y: 0,
    canvas: null,
    direction: 'up',
  }

  leftThreshold = 30
  rightThreshold = 70

  componentDidMount() {
    this.initCanvas();

    // window.addEventListener('resize', this.initCanvas);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.range !== this.state.range) {
      this.applyEffects();
    }
  }

  initCanvas = () => {
    const canvas = fx.canvas();

    const width = Math.min(window.innerWidth, document.documentElement.clientWidth);
    const height = Math.min(window.innerHeight, document.documentElement.clientHeight);

    const imgWidth = 270;
    const imgHeight = 480;
    const landscapeRatio = imgWidth / imgHeight;
    const portraitRatio = imgHeight / imgWidth;

    const orientation = width > height ? 'landscape' : 'portrait';

    if (orientation === 'landscape') {
      this.height = height;
      this.width = height * landscapeRatio;
    } else {
      this.width = width;
      this.height = width * portraitRatio;
    }

    this.image = new Image();
    this.image.src = IMG;
    this.image.width = this.width;
    this.image.height = this.height;
    this.image.onload = () => {
      this.generateTexture = () => canvas.texture(this.image);
      this.texture = canvas.texture(this.image);
      this.applyEffects();
      // this.interval = setInterval(() => this.autoSlide(), 40)
    }

    const main = document.querySelector('main');
    main.appendChild(canvas);
    this.canvas = canvas;
  }

  applyEffects = () => {
    const { range, y } = this.state;

    const blurVal = (range * 0.01);  //   0-100
    const toneVal = 20 - ((range * 0.0142) * 20);
    const toneAngle = (y * 0.01);

    if (range > this.rightThreshold) {
      this.texture.destroy();
      this.texture = this.generateTexture();
      this.canvas.draw(this.texture)
      .zoomBlur(this.width / 2, this.height / 2, blurVal)
      .update();

    } else if (range >= this.leftThreshold && range <= this.rightThreshold) {
      this.texture.destroy();
      this.texture = this.generateTexture();
      this.canvas.draw(this.texture)
      .zoomBlur(this.width / 2, this.height / 2, blurVal)
      .colorHalftone(this.width / 2, this.height / 2, toneAngle, toneVal)
      .update();
    } else if (range < this.leftThreshold) {
      this.texture.destroy();
      this.texture = this.generateTexture();
      this.canvas.draw(this.texture)
      .colorHalftone(this.width / 2, this.height / 2, toneAngle, toneVal)
      .update();
    }
  }

  onMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const range = ((e.clientX - rect.left) / this.width) * 100;
    const y = ((e.clientY - rect.top) / this.height) * 100;
    this.setState({
      range,
      y,
    });
  }

  onTouchMove = (e) => {
    const rect = e.target.getBoundingClientRect();

    let range;
    let y;

    try {
      const { touches } = e;
      const totals = Array.from(touches).map(({ clientX, clientY }) => {
        return [
          ((clientX - rect.left) / this.width) * 100,
          ((clientY - rect.top) / this.height) * 100,
        ];
      })
      .reduce((acc, item) => {
        acc[0] += item[0];
        acc[1] += item[1];
        return acc;
      }, [0, 0]);

      range = totals[0] / touches.length;
      y = totals[1] / touches.length;
    } catch (e) {
      range = ((e.touches[0].clientX - rect.left) / this.width) * 100;
      y = ((e.touches[0].clientY - rect.top) / this.height) * 100;
    }

    this.setState({
      range,
      y,
    });
  }

  onTouchEnd = (e) => {
    const rect = e.target.getBoundingClientRect();
    const range = ((e.nativeEvent.layerX - rect.left) / this.width) * 100;
    const y = ((e.nativeEvent.layerY - rect.top) / this.height) * 100;
    this.setState({
      range,
      y,
    });
  }

  setRange = (e) => {
    this.setState({
      range: e.target.value,
    })
  }

  render() {
    const { range } = this.state;

    const header = (
      <header className="App-header" style={{ zIndex: 100 }}>
        <input
          type="range"
          min="1"
          max="99"
          value={range}
          onChange={this.setRange}
        />
        <p>{range}</p>
      </header>
    );

    return (
      <div className="App">
        <main
          onMouseMove={this.onMouseMove}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
        />
      </div>
    )
  }
}

export default App;
